var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-3 px-3 py-1.5 items-center flex flex-row justify-between mx-4",class:{
    'rounded-t-xl': _vm.accordianState,
    'rounded-3xl': !_vm.accordianState,
    'datalist-header-bg': !_vm.manualGroup,
    'bg-tasqNeutral1400': _vm.manualGroup,
  }},[_c('div',{staticClass:"flex flex-row gap-x-2 items-center"},[(_vm.group.query !== 'ai_reports')?_c('span',{staticClass:"inline-block",class:_vm.isSelectedWell
          ? 'tasq-checkbox--checked'
          : 'tasq-checkbox checkbox-style',attrs:{"type":"checkbox"},on:{"click":_vm.toggleCheckbox}}):_vm._e(),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
        theme: 'tasq-tooltip',
        content: _vm.groupPreview,
      }),expression:"{\n        theme: 'tasq-tooltip',\n        content: groupPreview,\n      }",modifiers:{"bottom":true}}],staticClass:"max-w-xs overflow-hidden truncate text-sm font-semibold capitalize",class:{
        'gradient-text': !_vm.manualGroup,
        'text-tasqNeutral50': _vm.manualGroup,
      }},[_vm._v(" "+_vm._s(_vm.group.query === 'ai_reports' ? ' Tasq AI Reports' : _vm.group.query === 'audio_entry' ? 'Tasq AI Audio Entry' : _vm.group.query)+" ")]),(_vm.showDataEntryState && _vm.group.query !== 'ai_reports')?_c('TasqAiCommentEntry',{attrs:{"type-of-data":_vm.group.query}}):_vm._e(),(!_vm.hideAudioEntryUI && _vm.group.query === 'ai_reports')?_c('TasqAiAudioEntry',{on:{"close":_vm.refreshAudioEntryPopup}}):_vm._e(),(_vm.group.query !== 'ai_reports')?_c('div',{staticClass:"flex bg-tasqNeutral1400 h-5 text-center text-xxs font-medium rounded-3xl text-tasqNeutral50 flex-shrink-0 items-center"},[(_vm.group.wells.length)?_c('span',{staticClass:"m-auto px-1.5 font-semibold"},[_vm._v(_vm._s(_vm.group.wells.length)+" "),(!_vm.manualGroup && _vm.group.query !== 'audio_entry')?_c('span',{staticClass:"text-accentSuccess200"},[_vm._v("(+"+_vm._s(_vm.group.wells.length)+")")]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.group.wells && _vm.group.query !== 'ai_reports')?_c('p',{staticClass:"text-tasqNeutral50 text-xs font-normal"},[_vm._v(" "+_vm._s(_vm.sumByKey(_vm.group.wells))+" BOE affected ")]):_vm._e(),(_vm.group.wells.length === 0 && _vm.group.query !== 'ai_reports')?_c('tasq-tag',{attrs:{"label":"Real-Time"}},[_c('p',{staticClass:"text-xxxs maxxl:text-xxxxs h-0"},[_vm._v("Pending")])]):_vm._e(),(_vm.group.query !== 'ai_reports' &&  (!_vm.manualGroup ))?_c('VDropdown',{attrs:{"triggers":['click'],"theme":"tasq-dropdown","distance":5,"skidding":0},scopedSlots:_vm._u([{key:"popper",fn:function({ hide }){return [_c('div',{staticClass:"h-20"},_vm._l((_vm.downtimeReason),function(option,index){return _c('div',{key:index,staticClass:"cursor-pointer flex flex-col text-tasqNeutral50 overflow-hidden py-0",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"flex"},[_c('p',{staticClass:"w-full hover:bg-tasqNeutral1400 cursor-pointer text-xs font-normal rounded py-1.5",class:{
                  'bg-primary1300': _vm.groupViewState === option,
                },on:{"click":function($event){hide();
                  _vm.changeDowntimeReason(_vm.group.query, option);}}},[_c('span',{staticClass:"px-2.5 float-left"},[_vm._v(" "+_vm._s(option))])])])])}),0)]}}],null,false,807452489)},[_c('div',{staticClass:"flex flex-row gap-x-2 items-center"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            theme: 'tasq-tooltip',
            content: 'Automate Downtime Reason',
          }),expression:"{\n            theme: 'tasq-tooltip',\n            content: 'Automate Downtime Reason',\n          }",modifiers:{"top":true}}],staticClass:"cursor-pointer border border-transparent hover:border hover:border-tasqNeutral50 bg-tasqNeutral1400 flex flex-row items-center rounded-3xl px-3 text-xs font-medium py-1 text-tasqNeutral50"},[_c('span',[_vm._v(" "+_vm._s(_vm.selectedDowntimeReason))]),_c('span',[_c('mdicon',{staticClass:"text-tasqNeutral50",attrs:{"name":"chevron-down","size":'18'}})],1)])])]):_vm._e(),(_vm.selectedDowntimeReason !== 'Automate Downtime Reason')?_c('div',{staticClass:"text-tasqNeutral50 px-3 h-8 py-1.5 text-sm border cursor-pointer font-medium hover:border-primary500 border-tasqNeutral900 flex items-center gap-x-1 bg-tasqNeutral900 rounded bg",on:{"click":_vm.cancelDowntimeReason}},[_c('span',{},[_vm._v(" Cancel ")])]):_vm._e(),(_vm.selectedDowntimeReason !== 'Automate Downtime Reason')?_c('tasq-button',{staticClass:"font-inter text-sm h-8 w-24 float-right grounded-radiants",staticStyle:{"color":"white","background":"transparent"},attrs:{"size":"small"},on:{"click":_vm.saveDowntimeReason}},[_vm._v(" Save ")]):_vm._e()],1),_c('div',{staticClass:"flex flex-row gap-x-4 items-center"},[(_vm.isGroupOwner && _vm.group.query !== 'ai_reports')?_c('VDropdown',{attrs:{"triggers":['click'],"theme":"tasq-dropdown","distance":5,"skidding":-30},scopedSlots:_vm._u([{key:"popper",fn:function({ hide }){return [_c('div',{staticClass:"w-24"},_vm._l((_vm.settingsOptions),function(option,index){return _c('div',{key:index,staticClass:"cursor-pointer flex flex-col text-tasqNeutral50 overflow-hidden py-0",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"flex"},[_c('p',{staticClass:"w-full hover:bg-tasqNeutral1400 cursor-pointer text-xs font-normal rounded py-1.5",on:{"click":function($event){hide();
                  _vm.performAction(option);}}},[_c('span',{staticClass:"px-2.5 float-right"},[_vm._v(" "+_vm._s(option))])])])])}),0)]}}],null,false,2025993879)},[_c('div',{staticClass:"border-opacity-50 border-r w-14"},[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            theme: 'tasq-tooltip',
            content: 'Group List Settings',
          }),expression:"{\n            theme: 'tasq-tooltip',\n            content: 'Group List Settings',\n          }",modifiers:{"top":true}}],staticClass:"cursor-pointer bg-tasqNeutral800 w-7 rounded-full hover:bg-tasqNeutral50 text-center mx-3 p-1"},[_c('mdicon',{staticClass:"text-tasqNeutral50 hover:text-tasqNeutral900 rotate-180",attrs:{"name":"cog","size":20}})],1)])]):_vm._e(),(_vm.groupViewState === 'Data view' && _vm.group.query !== 'ai_reports')?_c('div',{staticStyle:{"rotate":"270deg"}},[_c('download-excel',{staticClass:"btn btn-default",attrs:{"data":_vm.group.wells,"worksheet":"My Worksheet","fields":_vm.jsonFields,"name":`${_vm.group.query}.xls`}},[_c('mdicon',{staticClass:"text-tasqNeutral50 rotate-180",attrs:{"name":"export","size":20}})],1)],1):_vm._e(),(_vm.group.query !== 'ai_reports')?_c('VDropdown',{attrs:{"triggers":['click'],"theme":"tasq-dropdown","distance":5,"skidding":0},scopedSlots:_vm._u([{key:"popper",fn:function({ hide }){return [_c('div',{staticClass:"w-28 h-20"},_vm._l((['In line view', 'Data view','Workflow View']),function(option,index){return _c('div',{key:index,staticClass:"cursor-pointer flex flex-col text-tasqNeutral50 overflow-hidden py-0",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"flex"},[_c('p',{staticClass:"w-full hover:bg-tasqNeutral1400 cursor-pointer text-xs font-normal rounded py-1.5",class:{
                  'bg-primary1300': _vm.groupViewState === option,
                },on:{"click":function($event){hide();
                  _vm.changeGroupView(_vm.group.query, option);}}},[_c('span',{staticClass:"px-2.5 float-left"},[_vm._v(" "+_vm._s(option))])])])])}),0)]}}],null,false,2657211498)},[_c('div',{staticClass:"flex flex-row gap-x-2 items-center"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            theme: 'tasq-tooltip',
            content: 'Change view',
          }),expression:"{\n            theme: 'tasq-tooltip',\n            content: 'Change view',\n          }",modifiers:{"top":true}}],staticClass:"cursor-pointer border border-transparent hover:border hover:border-tasqNeutral50 bg-tasqNeutral1400 flex flex-row items-center rounded-3xl px-3 text-xs font-medium py-1 text-tasqNeutral50"},[_c('span',[_vm._v(" "+_vm._s(_vm.groupViewState))]),_c('span',[_c('mdicon',{staticClass:"text-tasqNeutral50",attrs:{"name":"chevron-down","size":'18'}})],1)])])]):_vm._e(),_c('p',{on:{"click":function($event){return _vm.$emit('update-accordian-state')}}},[_c('mdicon',{staticClass:"text-tasqNeutral50",attrs:{"name":_vm.accordianState ? 'chevron-up' : 'chevron-down',"size":'24'}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }